<template>
  <VitePwaManifest />
  <client-only>
    <div class="bg-primary min-h-screen flex flex-col items-center" v-if="$device.isMobile">
      <div
        v-if="slots.title"
        class="quicksand-700 text-[40px] text-white text-center mt-20"
      >
        <slot name="title" />
      </div>
      <div class="flex-1 bg-white w-full rounded-t-[40px] flex flex-col items-center justify-between" :class="{'mt-20':!slots.title, 'mt-9':slots.title}">
        <slot />
        <div v-if="slots['btn-confirm']" class="mb-6 w-full max-w-xs">
          <slot name="btn-confirm" />
        </div>
      </div>
    </div>
    <div v-else>
        <QR />
      </div>
  </client-only>
</template>
<script setup>
const slots = useSlots();
</script>
